import useTranslation from "next-translate/useTranslation";
import CookieConsent from "react-cookie-consent";

const CookieBanner = ({acceptCookieConsent}) => {
  const { t } = useTranslation('common');

  return (
    <CookieConsent
      buttonText={t('cookie.accept')}
      enableDeclineButton
      onAccept={acceptCookieConsent}
      declineButtonText={t('cookie.reject')}
      style={styles.cookieBanner}
      buttonStyle={styles.btnStyleAccept}
      declineButtonStyle={styles.btnStyleDecline}
      contentStyle={styles.content}
    >
      <div className="cookie-banner">
        <div className="title">{t('cookie.bannerTitle')}</div>
        <div className="text">
          {t('cookie.bannerTextPrefix')}<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">{t('cookie.bannerTextSuffix')}</a>.
        </div>
      </div>
    </CookieConsent>


    // <section className="cookie-banner">
    //   <div className="auto-container">
    //     <div className="outer-box">
    //       <div className="sec-title">
    //         <div className="title">Korzystamy z plików cookies by ułatwić Ci korzystanie ze strony</div>
    //         <div className="text">
    //           Akceptując ciasteczka, wyrażasz zgodę na ich użycie <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Polityka prywatności</a>.
    //         </div>
    //       </div>
    //       <div className="btn-box">
    //         <button className="theme-btn btn-style-five" type="button" onClick={acceptCookies}>Akceptuję</button>
    //         <button className="theme-btn btn-style-six" type="button" onClick={declineCookies}>Odrzucam</button>
    //       </div>
    //       {/* End btn-box */}
    //     </div>
    //     {/* End outer-box */}
    //   </div>
    // </section>
  );
};

export default CookieBanner;


const styles = {
  cookieBanner: {
    backgroundColor: 'rgba(222, 235, 255,.75)',
    bottom: '10px',
    width: '100vw',
    padding: '5px 40px',
    textAlign: 'left',
    alignItems: 'center',
  },
  btnStyleAccept: {
    position: 'relative',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: '#1B8ABA',
    backgroundColor:  '#ffffff',
    border: '1px solid #ffffff',
    lineHeight: '20px',
    borderRadius: '8px',
    fontWeight: 400,
    padding: '10px 20px',
    margin: '0 10px',
  },
  btnStyleDecline: {
    position: 'relative',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: '#1B8ABA',
    backgroundColor:  'transparent',
    border: '1px solid #ffffff',
    lineHeight: '20px',
    borderRadius: '8px',
    fontWeight: 400,
    padding: '10px 20px',
    margin: '0 10px',
  },
  content: {
    color: '#000000',
  }
}